import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ThemeProvider } from "@mui/material"

import Layout from "../components/layout"
// import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

import Giftfinder from "../components/giftfinder/Giftfinder"
import { generalTheme } from "../generalTheme"



const IndexPage = () => (
  <ThemeProvider theme={generalTheme}>
    <Layout>
      {/* <Seo title="Home" /> */}
      <Giftfinder />

    </Layout>
  </ThemeProvider>
)

export default IndexPage
